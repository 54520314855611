
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

//import '@govflanders/vl-widget-polyfill';
import * as widgets from '@govflanders/vl-widget-client';
import * as HeaderTypes from'@govflanders/vl-widget-global-header-types';
import * as FooterTypes from '@govflanders/vl-widget-global-footer-types';

@Component
export default class WidgetEmbed extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public embedUrl!: string;

  @Prop({
    type: String,
    required: true,
  })
  public type!: string;

  @Prop({
    type: String,
    required: true,
  })
  public version: string;

  @Prop({
    type: String,
    required: true,
  })
  public widgetId!: string;

  mounted() {
    this.mountWidgets();
  }

  @Watch('embedUrl')
  private async mountWidgets(): Promise<any> {
    this.clearParent();

    if (this.version === 'v4') {
      return Promise.all([
        widgets
          .bootstrap(this.embedUrl)
          .then((widget) => {
            // Get the Citizen Profile Session extension from the global header widget.
            widget.getExtension('citizen_profile.session').then(function (session: any) {
              // This variable is purely to indicate which values are allowed (true / false).
              const websiteHasAuthenticatedSession = false;
              // Inform the session extension about the current session state of the website.
              session.configure({
                active: websiteHasAuthenticatedSession,
                endpoints: {
                  loginUrl: '/login',
                  loginRedirectUrl: '/profile',
                  logoutUrl: '/logout',
                },
              });
            });
            if (!document.getElementById('headerWrapperElement')) {
              const headerWrapperElement = document.createElement('div');
              headerWrapperElement.id = 'headerWrapperElement';
              const parentContainer = document.querySelector(
                '.widget-embed-placeholder-' + this.type,
              );
              widget.setMountElement(headerWrapperElement);
              widget.mount();
              parentContainer?.insertBefore(
                headerWrapperElement,
                parentContainer.childNodes[0] || null,
              );
            }
          })
          .catch((err) => {
            this.$emit('error', err);
            this.clearParent();
          }),
      ]);
    } else {
      const script = document.createElement('script');
      script.src = `https://test.widgets.burgerprofiel.dev-vlaanderen.be/api/v2/widget/${this.widgetId}/entry`;
      script.type = 'text/javascript';
      document.head.appendChild(script);
      script.onload = () => {
        const client = window.globalHeaderClient || window.globalFooterClient;
        client.mount();
      };
    }
  }

  private clearParent() {
    const parentContainer = document.querySelector('.widget-embed-placeholder-' + this.type);
    if (parentContainer) {
      parentContainer.innerHTML = '';
    }
  }
}
